<template>
  <div class="wenzheng container" v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <Advertisement :needTitle="false" :pos="'fzBQ29'" :path="path" :id="id" />
    <div class="article">
      <div class="article-title">{{ article.title }}</div>
      <div class="article-content">
        <p>{{ article.content }}</p>
      </div>
      <div class="wenzheng-commit">
        <div class="left">{{ commit.name }}</div>
        <div class="right">{{ commit.time }}</div>
      </div>
      <div class="horizontal-line"></div>
      <div class="timeline">
        <div class="statusline" v-for="(item, index) in timeList" :key="index">
          <div class="point">
            <div class="red"></div>
            <div class="line"></div>
          </div>
          <div class="status">{{ item.status }}</div>
          <div class="time">{{ item.time }}</div>
        </div>
      </div>
      <div class="article-content" v-if="commit.content.length > 0">
        {{ commit.content }}
      </div>
      <div class="horizontal-line" v-if="status == 2"></div>
      <div class="official-reply" v-if="status == 2">
        <span class="name">{{ reply.name }}</span>
        回复
      </div>
      <div class="article-content" :class="arrowShow ? 'vague' : ''" ref="article">
        <p>
          {{ reply.content }}
        </p>
        <img src="@/assets/images/arrow.png" class="arrow" v-if="arrowShow" @click="handleArrow" />
      </div>
    </div>
    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Advertisement from "@/components/advertisement";
  import Kcz from "@/components/kcz";
  import Tuijian from "@/components/tuijian";
  import OpenApp2 from "@/components/openApp2";
  import OpenApp from "@/components/openApp";
  import linkApp from "@/utils/linkApp";
  import { getWenzheng } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  export default {
    name: "wenzheng",
    components: { Tanchuang, Kcz, Tuijian, OpenApp2, OpenApp, Advertisement },
    data() {
      return {
        canShow: false,
        id: "",
        path: "wenzhengDetail",
        arrowShow: false,
        article: {
          title: "",
          content: "",
        },
        commit: {
          name: "",
          time: "",
          content: "",
        },
        reply: {
          name: "",
          content: ``,
        },
        timeList: [],
        status: 0, //0=》未受理，1=》已受理，2=》已收复
        share_config: {},
      };
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      getHeight() {
        let height = this.$refs.article.offsetHeight;
        if (height > 300) {
          this.arrowShow = true;
        } else {
          this.arrowShow = false;
        }
      },
      handleArrow() {
        this.arrowShow = false;
      },
      async getWenzheng(id) {
        const res = await getWenzheng(id);
        const data = res.data.data;
        this.article.title = data.title;
        this.article.content = data.content;
        this.commit.name = data.sorusernamet;
        this.commit.time = data.create_time.data.created_at;
        this.status = data.status;
        if (this.status == 2) {
          this.reply.name = data.answer_info.department_name;
          this.reply.content = data.answer_info.answer_content;
        }

        if (this.status == 1) {
          this.timeList = [
            {
              status: "已受理",
              time: data.accept_time,
            },
          ];
        }
        if (this.status == 2) {
          this.timeList = [
            {
              status: "已受理",
              time: data.accept_time,
            },
            {
              status: "已回复",
              time: data.answer_time,
            },
          ];
        }
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = data.title;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
    },
    mounted() {
      this.getHeight();
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getWenzheng(this.id);
    },
  };
</script>

<style lang="scss">
  .wenzheng {
    .wenzheng-commit {
      width: 92%;
      padding: 20px 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #1f1f1f;
        opacity: 1;
      }
      .right {
        font-size: 16px;
        font-weight: 500;
        line-height: 0.85rem;
        color: #9c9c9c;
        opacity: 1;
      }
    }
    .timeline {
      border-bottom: 1px solid #eee;
      .statusline {
        display: flex;
        align-items: center;
        height: 50px;
        margin: 0 auto;
        width: 92%;
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        &:nth-last-child(1) {
          border-bottom: none;
        }
        .point {
          .red {
            width: 10px;
            height: 10px;
            background-color: #f04040;
            border-radius: 50%;
            position: relative;
            margin-right: 5px;
            &::after {
              content: " ";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 5px;
              height: 5px;
              background-color: #fff;
              border-radius: 50%;
            }
          }
        }
        .status {
          flex: 1;
          font-size: 16px;
          font-weight: bold;
          color: #0b0b0b;
          opacity: 1;
        }
        .time {
          font-size: 14px;
          font-weight: 400;
          color: #9c9c9c;
          opacity: 1;
        }
      }
    }
    .official-reply {
      margin: 0 auto;
      width: 92%;

      font-size: 16px;
      font-weight: 800;
      line-height: 1.5;
      color: #303030;
      opacity: 1;
      margin-top: 20px;
      .name {
        font-size: 20px;
        font-weight: 800;
        line-height: 1.5;
        color: #f04040;
        opacity: 1;
        margin-right: 10px;
      }
    }
  }
</style>
